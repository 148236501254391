<template>
  <v-container fluid>
    <v-btn class="ma-2" tile large color="green darken-2" @click="printform()" icon>
      <v-icon>mdi-printer</v-icon>
    </v-btn>
    <h2>{{currentCase.participantDetails.firstname}} {{currentCase.participantDetails.lastname}}'s Alerts (Current)</h2>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="currentHeaders"
          :items="alerts"
          item-key="index"
          class="mr-2"
          :hide-default-footer="true"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.type }}</td>
              <td>{{ props.item.description}}</td>
              <td>{{ props.item.procedure}}</td>
              <td>{{ moment(props.item.expiry).format("DD-MM-YYYY")}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as alertsService from "../../../../services/alertsService";

import moment from "moment";
Vue.use(VeeValidate);

export default {
  name: "alerts",
  components: {},
  data: () => ({
    editedIndex: -1,
    vm: {},

    dialog: false,
    alerts: [],
    expiredAlerts: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    currentHeaders: [
      { text: "Type", value: "type" },
      { text: "Description", value: "description" },
      { text: "Procedure", value: "procedure" },
      { text: "Expires", value: "expires" },
      { text: "", value: "actions" }
    ],
    expiredHeaders: [
      { text: "Type", value: "type" },
      { text: "Description", value: "description" },
      { text: "Procedure", value: "procedure" },
      { text: "Expired", value: "expires" }
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    printform() {
      print();
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    init() {
      this.vm = {};
      const me = this;
      alertsService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let alerts = [];
          result.docs.map(doc => {
            const alert = doc.data();
            alert.id = doc.id;
            if (moment(alert.expiry).isBefore(new Date())) {
              me.expiredAlerts.push(alert);
            } else {
              alerts.push(alert);
            }
          });
          this.alerts = alerts;
        });
    },
    editItem(item) {
      this.editedIndex = this.alerts.indexOf(item);
      this.vm = Object.assign({}, item);
      this.dialog = true;
    },

    addAlert() {
      this.vm = {};
      this.dialog = true;
    },
    alertAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.alerts[this.editedIndex], data);
      } else {
        if (moment(data.expiry).isBefore(new Date())) {
          this.expiredAlerts.push(data);
        } else {
          this.alerts.push(data);
        }
      }
      this.alertClosed();
      console.log(data);
    },
    alertClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    }
  }
};
</script>
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>